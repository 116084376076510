/**
 * Keeps track of registered elements and components.
 * Upgrades and instantiates components when registered css classnames are found in the DOM.
 *
 * @memberof module:foundation
 * @version 1.5.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class ComponentHandler {
    /**
     * Initializes component handler.
     */
    constructor() {
        this.registeredItems = [];
        this.createdItems = [];
    }

    /**
     * Registers components.
     * @param {Object} item Object with component data to register.
     * @public
     */
    register(item) {
        // this.registeredItems.forEach((component) => {
        // // check for css classname already in use
        // if (component.cssClass === item.cssClass) {
        //     throw new Error('The provided cssClass has already been registered: ' + component.cssClass);
        // }

        // // check for classname already in use
        // if (component.className === item.classAsString) {
        //     throw new Error('The provided className has already been registered: ' + component.classAsString);
        // }
        // });
        // create component item
        const newItem = {
            classConstructor: item.constructor,
            className: item.classAsString,
            cssClass: item.cssClass,
            callbacks: [],
        };
        // register component
        this.registeredItems.push(newItem);
    }

    /**
     * Finds registered item by javascript class name.
     * @param {string} jsClass Javascript class name to find in registered items.
     * @returns {object|boolean} Found item or false.
     */
    findRegisteredItem(jsClass) {
        for (let i = 0; i < this.registeredItems.length; i++) {
            if (this.registeredItems[i].className === jsClass) {
                return this.registeredItems[i];
            }
        }
        return false;
    }

    /**
     * Gets list of already upgraded classes from DOM element.
     * @param {jQueryElement} $element jQuery element to fetch data from.
     * @returns {array} Array of found upgraded classes.
     */
    getUpgradedListOfElement($element) {
        const upgradedData = $element.attr('data-upgraded');
        if (typeof upgradedData === 'undefined') {
            return [];
        } else {
            return upgradedData.split(',');
        }
    }

    /**
     * Returns true if given element is already upgraded by given javascript class.
     * @param {jQueryElement} $element jQuery element to check for javascript classes
     * @param {string} jsClass Javascript class to check.
     * @returns {boolean} True if element is already upgraded.
     */
    isElementUpgraded($element, jsClass) {
        const upgradedList = this.getUpgradedListOfElement($element);
        return upgradedList.indexOf(jsClass) !== -1;
    }

    /**
     * Get all registered items and upgrades the DOM elements.
     * @public
     */
    upgradeAllRegistered() {
        for (let i = 0; i < this.registeredItems.length; i++) {
            this.upgradeDOM(this.registeredItems[i].className);
        }
    }

    /**
     * Upgrades DOM elements by javascript classname and optional css class name.
     * @param {string} jsClass Javascript class name.
     * @param {string} [cssClass] Optional css class name for elements.
     * @public
     */
    upgradeDOM(jsClass, cssClass) {
        const className = jsClass;
        if (typeof cssClass === 'undefined') {
            const registeredItem = this.findRegisteredItem(className);
            if (registeredItem) {
                cssClass = registeredItem.cssClass;
            }
        }
        const $elements = $('.' + cssClass);
        for (let i = 0; i < $elements.length; i++) {
            this.upgradeElement($($elements[i]), className);
        }
    }

    /**
     * Instanciate javascript class and upgrades DOM element.
     * @param {jQueryElement} $element jQuery element to upgrade.
     * @param {string} [jsClass] Otional javascript class name to upgrade to.
     * @public
     */
    upgradeElement($element, jsClass) {
        // get list of already upgraded classnames of element
        const upgradedList = this.getUpgradedListOfElement($element);
        let classesToUpgrade = [];

        if (typeof jsClass === 'undefined') {
            const classList = $element[0].classList;
            this.registeredItems.forEach((item) => {
                if (
                    classList.contains(item.cssClass) &&
                    classesToUpgrade.indexOf(item) === -1 &&
                    this.isElementUpgraded($element, item.className) === false
                ) {
                    classesToUpgrade.push(item);
                }
            });
        } else if (this.isElementUpgraded($element, jsClass) === false) {
            classesToUpgrade.push(this.findRegisteredItem(jsClass));
        }

        for (let i = 0; i < classesToUpgrade.length; i++) {
            const registeredClass = classesToUpgrade[i];

            // require class
            upgradedList.push(registeredClass.className);
            $element.attr('data-upgraded', upgradedList.join(','));

            // create class instance
            const instance = new registeredClass.classConstructor($element);
            instance.itemProperty = registeredClass;
            instance.init();

            this.createdItems.push(instance);

            for (let j = 0; j < registeredClass.callbacks.length; j++) {
                registeredClass.callbacks[j]($element);
            }
        }
    }

    /**
     * Upgrade a list of dom elements
     * @param {jQueryElement} $elements jQuery elements to upgrade.
     * @public
     */
    upgradeElements($elements) {
        for (let i = 0; i < $elements.length; i++) {
            this.upgradeElement($($elements[i]));
        }
    }

    /**
     * Registers callbacks to upgrades performed on given javascript class name.
     * @param {string} jsClass Javascript class to hook into for upgrades.
     * @param {function} callback Callback function returns one parameter: jQuery element which got upgraded.
     * @public
     */
    registerUpgradedCallback(jsClass, callback) {
        const registeredClass = this.findRegisteredClass(jsClass);
        if (registeredClass) {
            registeredClass.callbacks.push(callback);
        }
    }

    /**
     * Fetches current registered items.
     * @returns {array} Array of currently registered items.
     * @public
     */
    getRegisteredItems() {
        return this.registeredItems;
    }

    /**
     * Fetches current created items.
     * @param {string} [className] Optional instance classname of created items.
     * @returns {array} Array of currently created items.
     * @public
     */
    getCreatedItems(className) {
        let returnArray = [];
        if (typeof className !== 'undefined') {
            const itemsLength = this.createdItems.length;
            for (let i = 0; i < itemsLength; i++) {
                if (this.createdItems[i].itemProperty.className === className) {
                    returnArray.push(this.createdItems[i]);
                }
            }
        } else {
            returnArray = this.createdItems;
        }
        return returnArray;
    }
}

export default ComponentHandler;
