/**
 * The Foundation module contains all fundamental basic functionality needed for the website.
 * It sets up the global viewport variable, to determine current breakpoint, adds svg icons to the DOM and
 * initializes the ComponentHandler to automatically start feature component code.
 *
 * @module foundation
 */
import '@babel/polyfill';
import 'promise-polyfill/src/polyfill.js';
import viewport from 'responsive-toolkit';
import ComponentHandler from './lib/ComponentHandler.js';

// is the page loaded in Sitecore Editor mode?
let scModes = ['edit', 'preview', 'debug'];
window.scEditMode = false;
for (let i = 0; i < scModes.length; i++) {
    if ($('body').hasClass('pagemode-' + scModes[i])) {
        window.scEditMode = scModes[i];
    }
}

// responsive toolkit bootstrap 4 support
viewport.use('Bootstrap4', {
    xs: $('<div class="d-xs-block d-sm-none"></div>'),
    sm: $('<div class="d-none d-sm-block d-md-none"></div>'),
    md: $('<div class="d-none d-md-block d-lg-none"></div>'),
    lg: $('<div class="d-none d-lg-block d-xl-none"></div>'),
    xl: $('<div class="d-none d-xl-block"></div>'),
});

// add to window scope to make it global
window.viewport = viewport;

// init Component Handler so components can register themselves
window.ComponentHandler = new ComponentHandler();
